const translateConfig = {
  zh: {
    orderList: {
      ActualDate: '实际日期',
      ActualDeliveryDate: '实际交期',
      ActualReceivePaymentTime: '实际收款金额',
      Add: '新增',
      AdjustmentDescription: '调整说明',
      AdvancedFilter: '高级筛选',
      AgreedDeliveryDate: '约定收货日期',
      AgreedShipmentDate: '约定装运日期',
      All: '全部',
      Appendix: '附件',
      ApprovalDate: '审核通过日期',
      ApprovalTime: '审核通过时间',
      Area: '地区',
      ArrangeOrder: '安排订单',
      AssociateQuotationNumber: '关联报价单号',
      AutoFinish: '自动完结',
      BeenStorage: '已入库',
      BeingRevised: '修改中',
      Buyer: '采购员',
      Cancelled: '已取消',
      Choose: '选择',
      Clone: '克隆',
      CompanyName: '公司名称',
      Completed: '已完成',
      Contract: '合同',
      ContractSigningTime: '合同签订时间',
      CreationTime: '创建时间',
      Creator: '创建人',
      CurrentProgress: '当前进度',
      CustomDisplay: '自定义显示',
      CustomerConfirmation: '客户确认中',
      CustomerHasPaidToBeCredited: '客户已付待入账',
      CustomerOrderNumber: '客户订单号',
      CustomerRepresentative: '客户代表',
      CustomerSignatureOption: '客户签收选择',
      DateOfReceipt: '签收日期',
      DateOfSigning: '签约日期',
      DateUpdater: '日期更新人',
      Deferment: '延期',
      DeferredDays: '延期天数',
      Delete: '删除',
      Delivered: '已收货',
      DocumentaryNode: '跟单节点',
      DocumentaryProgress: '跟单进度',
      EstimatedDate: '预计日期',
      Export: '导出',
      ExportAll: '导出全部',
      ExportSelected: '导出选中',
      FeeApplication: '费用申请',
      FinanceToBeConfirmed: '财务待确认',
      FinishType: '完结类型',
      HasBeenConfirmed: '已确认',
      Hefei: '合肥',
      InDelivery: '出库中',
      InProcurement: '采购中',
      InReview: '审核中',
      Inputting: '录入中',
      InspectionDate: '验货日期',
      InspectionOrderNo: '验货单号',
      InspectionResult: '验货结果',
      Invalid: '作废',
      LastReceivePaymentTime: '最近收款时间',
      LogisticsFreightOptions: '物流货运选择',
      LogisticsOrderNo: '物流单号',
      ManualSettlement: '手动结算',
      ManuallyFinish: '手动完结',
      NoFile: '没有文件',
      NoSettlement: '未结算',
      NodeName: '节点名称',
      Operator: '操作人',
      Order: '下单',
      OrderNumber: '订单编号',
      OrderProcess: '订单进程',
      OrderQuantity: '订单数量',
      OrderSource: '订单来源',
      OrderSupport: '订单支持',
      OrderTime: '下单时间',
      OrderTrackingNumber: '订单跟踪编号',
      OriginalOrderAmount: '订单原金额',
      OutOfWarehouse: '已出库',
      OutboundOrderNumber: '出库委托单号',
      PartialPayment: '部分收款',
      Passed: '已通过',
      PassedTheReview: '审核通过',
      PaymentReceived: '已收款',
      PendingArrangement: '待安排',
      PendingDelivery: '待收货',
      PendingPurchase: '待采购处理',
      PendingShipment: '待发货',
      PlannedShippingTime: '计划运输时间',
      PrintingMethod: '印刷方式',
      PrintingRenderings: '印刷效果图',
      Process: '进程',
      CustomerEvaluationNumber: '客评单号',
      Procurement: '采购',
      ProductCategory: '产品类别',
      ProductCode: '产品编号',
      ProductInformation: '产品信息',
      ProductName: '产品名称',
      ProductProgress: '产品进度',
      ProductQuantity: '产品数量',
      ProductStatus: '产品状态',
      PurchaseOrderNumber: '采购单号',
      Purchased: '已采购',
      QCQualityInspectionOptions: 'QC质检选择',
      ReasonForRejection: '驳回原因',
      ReasonForRevocation: '撤销原因',
      ReasonRejection: '驳回原因',
      ReceiptAmount: '收款金额',
      ReceivePayment: '收款',
      ReceivePaymentNumber: '收款单号',
      ReceivePaymentProgress: '收款进度',
      ReceivePaymentTime: '收款时间',
      RefundApplication: '退款申请',
      Rejected: '已驳回',
      RejectedOutOfWarehouse: '出库驳回',
      RejectionDescription: '驳回说明',
      Remark: '备注',
      RenderingsStatus: '效果图状态',
      Return: '返回',
      ReviewDismissed: '审核驳回',
      ReviewRejectionTime: '审核驳回时间',
      ReviewRevocationTime: '审核撤销时间',
      Revoked: '已撤销',
      Save: '保存',
      Search: '搜索',
      Settled: '已结算',
      SettlementAdjustment: '结算调整',
      SettlementStatus: '结算状态',
      Settling: '结算中',
      Shipped: '已出运',
      ShippedQuantity: '已出运数量',
      ShippingConsignment: '出运委托',
      ShippingDate: '出货日期',
      ShippingOrderNumber: '委托单号',
      Status: '状态',
      Suzhou: '苏州',
      TimeAdjustment: '调整时间',
      TimeRejection: '驳回时间',
      Tips: '提示：录入【实际日期】请确认无误，实际录入保存，不再支持修改',
      ToBeProcessed: '待处理',
      ToSettled: '待结算',
      SettlementReject: '结算驳回',
      TotalOrderAmount: '订单总金额',
      UnderInspection: '验货中',
      Unpaid: '未收款',
      UnsettledReason: '未结算原因',
      Voided: '已作废',
      Yiwu: '义乌',
      Notoutofstock: '未出库',
      NotesOnOrder: '订单历史问题备注',
      Empty: '空',
      HaveAvalue: '有值',
      complain: '投诉',
      feedback: '反馈',
      deliveryDate: '交货日期',
      CustomerHasSubmitted: '客户已提交',
      invoice: '打印发票',
      generateinvoice: '生成发票',
      date: '选择日期',
      servicecharge: '手续费',
      orderinvoicehasalreadybeencreated:
        ' 注：当前订单已生成发票，可以在订单详情中查看',
      sendinvoice: '发票通过邮件发送给客户',
      CustomerHasUploaded: '客户已上传',
      onlinePayment: '在线支付',
      paymentMilestones: '收款节点',
      expectedPaymentDate: '预计收款时间',
      receivableAmount: '应收金额',
      receivedAmount: '已收金额',
      onlinePaymentAmount: '在线支付金额',
      generateLink: '生成链接',
      cancel: '取消',
      tip: '提示：有进行中的收款单',
      link: '链接：',
      copy: '复制',
      sendEmail: '发送邮件',
      operatorCreator: '请输入产品运营/创建人',
    },
  },
  en: {
    orderList: {
      ActualDate: 'Actual Date',
      ActualDeliveryDate: 'Actual Delivery Date',
      ActualReceivePaymentTime: 'Actual Payment Amount',
      Add: 'Add',
      AdjustmentDescription: 'Adjustment Description',
      AdvancedFilter: 'Advanced Search',
      AgreedDeliveryDate: 'Agreed Delivery Date',
      AgreedShipmentDate: 'Agreed Shipment Date',
      All: 'All',
      Appendix: 'Appendix',
      ApprovalDate: 'Approval Date',
      ApprovalTime: 'Approval Time',
      Area: 'Region',
      ArrangeOrder: 'Arrange Order',
      AssociateQuotationNumber: 'Associate Quotation Number',
      AutoFinish: 'Auto Finish',
      BeenStorage: 'Been Storage',
      BeingRevised: 'Being Revised',
      Buyer: 'Buyer',
      Cancelled: 'Cancelled',
      Choose: 'Choose',
      Clone: 'Clone',
      CompanyName: 'Company',
      Completed: 'Completed',
      Contract: 'Contract',
      ContractSigningTime: 'Contract Signing Time',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      CurrentProgress: 'Current Progress',
      CustomDisplay: 'Customize Display',
      CustomerConfirmation: 'Customer Confirmation',
      CustomerHasPaidToBeCredited: 'Customer Has Paid To Be Credited',
      CustomerOrderNumber: 'PO#',
      CustomerRepresentative: 'Sales Rep',
      CustomerSignatureOption: 'Customer Signature Option',
      DateOfReceipt: 'Date Of Receipt',
      DateOfSigning: 'Order Date',
      DateUpdater: 'Date Updater',
      Deferment: 'Deferment',
      DeferredDays: 'Deferred Days',
      Delete: 'Delete',
      Delivered: 'Delivered',
      DocumentaryNode: 'Documentary Node',
      DocumentaryProgress: 'Production Progress',
      EstimatedDate: 'Estimated Date',
      Export: 'Export',
      ExportAll: 'Export All',
      ExportSelected: 'Export Selected',
      FeeApplication: 'Fee Application',
      FinanceToBeConfirmed: 'Finance To Be Confirmed',
      FinishType: 'Completion Type',
      HasBeenConfirmed: 'Has Been Confirmed',
      Hefei: 'Hefei',
      InDelivery: 'In Delivery',
      InProcurement: 'In Procurement',
      InReview: 'In Review',
      Inputting: 'Being Processed',
      InspectionDate: 'Inspection Date',
      InspectionOrderNo: 'Inspection Order No.',
      InspectionResult: 'Inspection Result',
      Invalid: 'Invalid',
      LastReceivePaymentTime: 'Last Receive Payment Time',
      LogisticsFreightOptions: 'Logistics Freight Options',
      LogisticsOrderNo: 'Logistics Order No.',
      ManualSettlement: 'Manual Settlement',
      ManuallyFinish: 'Manually Finish',
      NoFile: 'No File',
      NoSettlement: 'No Settlement',
      NodeName: 'Node Name',
      Operator: 'Operator',
      Order: 'Order',
      OrderNumber: 'Order Number',
      OrderProcess: 'Order Process',
      OrderQuantity: 'Order Quantity',
      OrderSource: 'Order Source',
      OrderSupport: 'Sales Assistant',
      OrderTime: 'Order Time',
      OrderTrackingNumber: 'Order Tracking NO. ',
      OriginalOrderAmount: 'Original Order Amount',
      OutOfWarehouse: 'Stock-Out',
      OutboundOrderNumber: 'Outbound Order Number',
      PartialPayment: 'Partial Payment',
      Passed: 'Passed',
      PassedTheReview: 'Passed The Review',
      PaymentReceived: 'Payment Received',
      PendingArrangement: 'Pending Arrangement',
      PendingDelivery: 'Pending Delivery',
      PendingPurchase: 'Pending Purchase',
      PendingShipment: 'Pending Shipment',
      PlannedShippingTime: 'Planned Shipping Time',
      PrintingMethod: 'Imprint Method',
      PrintingRenderings: 'Virtual Proof',
      Process: 'Order Progress',
      CustomerEvaluationNumber: 'Feedback Number',
      Procurement: 'Procurement',
      ProductCategory: 'Product Category',
      ProductCode: 'Item No.',
      ProductInformation: 'Product Information',
      ProductName: 'Item Name',
      ProductProgress: 'Product Progress',
      ProductQuantity: 'Product Quantity',
      ProductStatus: 'Product Status',
      PurchaseOrderNumber: 'Purchase Order Number',
      Purchased: 'Purchased',
      QCQualityInspectionOptions: 'QC Quality Inspection Options',
      ReasonForRejection: 'Reason For Rejection',
      ReasonForRevocation: 'Reason For Revocation',
      ReasonRejection: 'Reason Rejection',
      ReceiptAmount: 'Receipt Amount',
      ReceivePayment: 'Receive Payment',
      ReceivePaymentNumber: 'Receive Payment Number',
      ReceivePaymentProgress: 'Receive Payment Progress',
      ReceivePaymentTime: 'Receive Payment Time',
      RefundApplication: 'Refund Application',
      Rejected: 'Rejected',
      RejectedOutOfWarehouse: 'Rejected Out Of Warehouse',
      RejectionDescription: 'Rejection Description',
      Remark: 'Remark',
      RenderingsStatus: 'Virtual Proof Status',
      Return: 'Return',
      ReviewDismissed: 'Review Dismissed',
      ReviewRejectionTime: 'Review Rejection Time',
      ReviewRevocationTime: 'Review Revocation Time',
      Revoked: 'Revoked',
      Save: 'Save',
      Search: 'Search',
      Settled: 'Settled',
      SettlementAdjustment: 'Settlement Adjustment',
      SettlementStatus: 'Settlement Status',
      Settling: 'Settling',
      Shipped: 'Shipped',
      ShippedQuantity: 'Shipped Quantity',
      ShippingConsignment: 'Shipping Consignment',
      ShippingDate: 'Shipping Date',
      ShippingOrderNumber: 'Shipping Order Number',
      Status: 'Status',
      Suzhou: 'Suzhou',
      TimeAdjustment: 'Time Adjustment',
      TimeRejection: 'Time Rejection',
      Tips: 'Reminder: Please confirm the input [actual date], the actual input is saved, and modification is no longer supported.',
      ToBeProcessed: 'To Be Processed',
      ToSettled: 'To Settled',
      SettlementReject: 'Settlement Reject',
      TotalOrderAmount: 'Order Amount',
      UnderInspection: 'Under Inspection',
      Unpaid: 'Unpaid',
      UnsettledReason: 'Unsettled Reason',
      Voided: 'Voided',
      Yiwu: 'Yiwu',
      Notoutofstock: 'Not out of stock',
      NotesOnOrder: 'Notes on order history issues',
      Empty: 'Empty',
      HaveAvalue: 'Have a value',
      complain: 'complain',
      feedback: 'feedback',
      deliveryDate: 'delivery date',
      CustomerHasSubmitted: 'Customer has submitted',
      invoice: 'invoice',
      generateinvoice: 'generate invoice',
      date: 'date',
      servicecharge: 'Handling Fee',
      orderinvoicehasalreadybeencreated:
        'Note ：order invoice has already been created, please check in order details',
      sendinvoice: 'send the invoice to customer via E-mail',
      CustomerHasUploaded: 'Customer has uploaded',
      onlinePayment: 'Online Payment',
      paymentMilestones: 'Payment milestones',
      expectedPaymentDate: 'Expected Payment Date',
      receivableAmount: 'Receivable Amount',
      receivedAmount: 'Received Amount',
      onlinePaymentAmount: 'Online Payment Amount',
      generateLink: 'Generate link',
      cancel: 'Cancel',
      tip: 'Tip: There are in-progress receipts',
      link: 'Link:',
      copy: 'Copy',
      sendEmail: 'Send Email',
      operatorCreator: 'Enter product operator/creator',
    },
  },
}

export default translateConfig
