/* eslint-disable prettier/prettier */
import {
  listPageMallOrder,
  getMallOrderVO,
  insertOrderToMall,
  saveMallOrder
} from '@/core/services/api/mall-order'

class MallOrderInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   *根据分页查找列表
   * @param  {Object} data - The data object containing the parameters.
   * @param  {type} data.pageLe -分页数量
   * @param  {type} data.pageNo - 分页页码
   * @param  {type} data.mallOrderCode - 商城订单号
   * @param  {type} data.companyName - 公司名称
   */
  async listPageMallOrderApi(data) {
    const { pageLe, pageNo, mallOrderCode, companyName, handleFlag } = data
    return await listPageMallOrder({
      pageLe,
      pageNo,
      mallOrderCode,
      companyName,
      handleFlag,
    })
  }

  /**
   *获取订单详情
   * @param  {id} 商城订单id
   */
  async getMallOrderVOApi(id) {
    return await getMallOrderVO(id)
  }

  // 处理订单
  async insertOrderToMallApi(data) {
    return await insertOrderToMall(data)
  }

  async saveMallOrderApi(data) {
    return await saveMallOrder(data)
  }
}

MallOrderInteractor._instance = new MallOrderInteractor()

export default MallOrderInteractor.getInstance()
