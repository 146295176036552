const translateConfig = {
  zh: {
    auditManage: {
      AuditType: '审核类型',
      CustomerReviewCenter: '客评中心',
      PendingApproval: '待审核',
      Approved: '已审核',
      SubmittedByMe: '我发起的',
      CopiedMe: '抄送我的',
      ReviewNumber: '审核单号',
      FeedbackNumber: '客评编号',
      Region: '所属地区',
      Applicant: '申请人',
      ApplicationTime: '申请时间',
      Operate: '操作',
      ShowRegion: '展示所属地区',
      DataOnComplaints: '投诉数据',
      RecordOfReply: '回复记录',
      StartDate: '申请时间:开始日期',
      DateClosed: '结束日期',
      BatchAudit: '批量审核',
      Audit: '审核',
      AuditContent: '审核内容',
      AuditProgress: '审核进度',
      Agreement: '同意',
      Loading: '加载中',
      RejectionInBatches: '批量驳回',
      TurnDown: '驳回',
      OpinionOfRejection: '驳回意见',
      BatchRemarks: '批量备注',
      Confirm: '确认',
      Cancel: '取消',
      View: '查看',

      Tips1: '【单号存在与客户不匹配】提示：录入的单号不属于当前客户的，请确认',
      Tips2: '【单号不存在】提示：当前录入的单号在系统中不存在，请确认',
      Tips3: '请输入您的审核驳回意见',
    },
  },
  en: {
    auditManage: {
      AuditType: 'Review Type',
      CustomerReviewCenter: 'Customer Review Center',
      PendingApproval: 'Pending Approval',
      Approved: 'Approved',
      SubmittedByMe: 'Submitted by Me',
      CopiedMe: 'Copied Me',
      ReviewNumber: 'Review Number',
      FeedbackNumber: 'Feedback Number',
      Region: 'Region',
      Applicant: 'Applicant',
      ApplicationTime: 'Application Time',
      Operate: 'Operate',
      ShowRegion: 'Show Region',
      DataOnComplaints: 'Feedback Record',
      RecordOfReply: 'Historical Description',
      StartDate: 'Start Date',
      DateClosed: 'End Date',
      BatchAudit: 'Batch Audit',
      Audit: 'Review',
      AuditContent: 'Review Content',
      AuditProgress: 'Review Progress',
      Agreement: 'Close',
      Loading: 'Loading',
      RejectionInBatches: 'Rejection In Batches',
      TurnDown: 'Reject',
      OpinionOfRejection: 'Opinion Of Rejection',
      BatchRemarks: 'Batch Remarks',
      Confirm: 'Confirm',
      Cancel: 'Cancel',
      View: 'View',

      Tips1:
        'The Feedback Number does not belong to the current customer, please confirm.',
      Tips2: 'The Feedback Number does not exist, please confirm.',
      Tips3: 'Please enter your review rejection opinion',
    },
  },
}

export default translateConfig
