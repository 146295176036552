/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import Vue from 'vue'
import {
  userLogin,
  loginOut,
  getLoginUser,
  getAuthority,
  getMultiTenant,
} from '@/api/public'

import { getListDepartmrnt } from '@/api/organization-manage'
import {
  getToken,
  removeToken,
  setToken,
  getRefreshToken,
  removeRefreshToken,
  setRefreshToken,
} from '@/utils/token'
import { handleOutProcessCache } from '@/utils/custom-cache'
import { title } from '@/config'
import { LOGIN_INFO } from '@/config/loginInfo'
import Layout from '@/erp/layouts'

let user =
  localStorage.getItem('setUserInfo') &&
  localStorage.getItem('setUserInfo') != 'undefined'
    ? JSON.parse(localStorage.getItem('setUserInfo'))
    : {}
let companyName = localStorage.getItem('setCompanyName')
  ? JSON.parse(localStorage.getItem('setCompanyName'))
  : ''

const state = () => ({
  token: getToken(),
  refreshToken: getRefreshToken(),
  userInfo: user,
  username: '',
  companyName: companyName,
  slideRouters: [],
  routers: [],
  permissions: [],
  avatar: '',
  tenantInfo: [], //多租户
})
const getters = {
  token: (state) => state.token,
  refreshToken: (state) => state.refreshToken,
  userInfo: (state) => state.userInfo,
  username: (state) => state.username,
  companyName: (state) => state.companyName,
  avatar: (state) => state.avatar,
  slideRouters: (state) => state.slideRouters,
  routers: (state) => state.routers,
  permissions: (state) => state.permissions,
  tenantInfo: (state) => state.tenantInfo,
}
const mutations = {
  //token
  setToken(state, token) {
    state.token = token
    setToken(token)
  },
  // refreshToken
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken
    setRefreshToken(refreshToken)
  },

  //设置用户信息
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },

  //多租户信息
  setTenantInfo(state, tenantInfo) {
    state.tenantInfo = tenantInfo
  },

  //设置用户名
  setUsername(state, username) {
    state.username = username
  },
  //设置公司名
  setCompanyName(state, companyName) {
    state.companyName = companyName
  },

  //路由
  SET_ROUTERS(state, data) {
    state.routers = data
    state.slideRouters = data
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions
  },

  //设置头像
  setAvatar(state, avatar) {
    state.avatar = avatar
  },
}
const actions = {
  //设置token
  setToken({ commit }, token) {
    commit('setToken', token)
  },

  //设置refreshtoken
  setRefreshToken({ commit }, refreshToken) {
    commit('setRefreshToken', refreshToken)
  },
  //设置头像
  setAvatar({ commit }, avatar) {
    commit('setAvatar', avatar)
  },

  //登录
  async login({ commit }, { userInfo, ctx, fn, res }) {
    try {
      // const { data: res } = await userLogin(userInfo).catch((err) => {
      //   throw new Error(err)
      // })
      commit('setToken', res.accessToken)
      // 设置token时间
      localStorage.setItem('tokenTime', new Date().getTime())
      // setRefreshToken
      commit('setRefreshToken', res.refreshToken)

      //获取用户信息
      let response1 = await getLoginUser()

      ctx.$store.commit(
        'settings/changeLanguage',
        response1?.data.area === '美国' ? 'en' : 'zh'
      )
      ctx.$i18n.locale = response1?.data.area === '美国' ? 'en' : 'zh'

      commit('setUserInfo', response1.data)
      localStorage.setItem('setUserInfo', JSON.stringify(response1.data))
      commit('setUsername', response1.data.userName)
      commit('setAvatar', response1.data.headUrl)
      localStorage.setItem('userName', response1.data.userName)
      // 缓存登录信息，多窗口切换账号需要用
      LOGIN_INFO.userId = response1.data.userId
      localStorage.setItem('login_userId', response1.data.userId)

      //路由权限----------------------------------
      let response = await getAuthority({})
      if (response.data.length) {
        localStorage.setItem(
          'routers',
          JSON.stringify(response.data[0].children)
        )
      } else {
        return Vue.prototype.$baseNotify('请联系管理员配置权限!', '', 'error')
      }

      //获取组织
      let response2 = await getListDepartmrnt()
      if (response2.code === '000000') {
        commit('setCompanyName', response2.data[0].name)
        localStorage.setItem(
          'setCompanyName',
          JSON.stringify(response2.data[0].name)
        )
      }

      // //获取多租户
      let response3 = await getMultiTenant()
      if (response3.code === '000000') {
        commit('setTenantInfo', response3.data)
        localStorage.setItem('setTenantInfo', JSON.stringify(response3.data))
      }

      fn(response.data[0].children)

      const hour = new Date().getHours()
      const thisTime =
        // hour < 8
        //   ? '早上好'
        //   : hour <= 11
        //   ? '上午好'
        //   : hour <= 13
        //   ? '中午好'
        //   : hour < 18
        //   ? '下午好'
        //   : '晚上好'

        hour < 8
          ? ctx.$t('other.good1')
          : hour <= 11
          ? ctx.$t('other.good2')
          : hour <= 13
          ? ctx.$t('other.good3')
          : hour < 18
          ? ctx.$t('other.good4')
          : ctx.$t('other.good5')
      Vue.prototype.$baseNotify(
        `${ctx.$t('other.welLogin')}${title}`,
        `${thisTime}！`
      )
    } catch {
      return Promise.reject('error happend!')
    }
  },

  // 获取用户名下租户
  async getMultiTenantTest({ commit }) {
    return new Promise((resolve, reject) => {
      getMultiTenant()
        .then((r) => {
          if (r.code === '000000') {
            commit('setTenantInfo', r.data)
            resolve(r.data)
          } else {
            reject(r.data)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // 获取用户权限
  async getUserAccess({ commit, dispatch }) {
    let response = await getAuthority({})
    if (response?.data?.length) {
      localStorage.setItem('routers', JSON.stringify(response.data[0].children))
    } else {
      this.$router.push('/login')
      return Vue.prototype.$baseNotify('请联系管理员配置权限!', '', 'error')
    }
  },
  //获取路由
  async generateRouters({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_ROUTERS', '')
      let accessRouters = filterAsyncRouter(JSON.parse(JSON.stringify(data)))
      commit('SET_ROUTERS', accessRouters)

      commit('SET_PERMISSIONS', filterPermissions(data))
      resolve(accessRouters)
    })
  },

  //退出登录
  async logout({ dispatch }) {
    await loginOut()
    await dispatch('resetAll')
  },

  //获取用户信息
  async getUserInfo({ commit, dispatch }) {
    let response = await getLoginUser()

    commit('setUserInfo', response?.data)
    commit('setUsername', response?.data?.userName)
  },

  /**
   * @description 重置token、roles、ability、router、tabsBar等
   * @param {*} { commit, dispatch }
   */

  async resetAll({ commit, dispatch }) {
    commit('setUsername', '游客')
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
    //commit('routes/setRoutes', [], { root: true })
    commit('SET_ROUTERS', [])
    commit('SET_PERMISSIONS', [])
    commit('setCompanyName', '')
    commit('setTenantInfo', [])
    commit('mail/setAccountList', [], { root: true })
    commit('mail/setCurrentAccount', '', { root: true })
    commit('mail/setLatestContacts', [], { root: true })
    commit('mail/setReceiveCatgoryList', [], { root: true })
    commit('mail/setContactGroupList', [], { root: true })
    handleOutProcessCache()

    sessionStorage.clear()

    await dispatch('setToken', '')
    await dispatch('setRefreshToken', '')
    await dispatch('acl/setFull', false, { root: true })
    await dispatch('acl/setRole', [], { root: true })
    await dispatch('acl/setAbility', [], { root: true })
    await dispatch('tabs/delAllVisitedRoutes', [], { root: true })
    removeToken()
    removeRefreshToken()
  },

  /**
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true })
    commit('setAvatar', '')
    commit('setUsername', 'admin(未开启登录拦截)')
  },
}
export default { state, getters, mutations, actions }

const ParentView = { render: (e) => e('router-view') }

function firstToUpper(str) {
  if (str) {
    let test = str
      .split('-')
      .map((ele) => {
        return ele.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
      })
      .join('')
    return test
  }
}

//需要缓存的路由
export const catchRouteArr = [
  'CustomerCenter',
  'ClientMange',
  'SupplierManage',
  'InquiryManage',
  'OrderList',
  'purchaseDemand',
  'purchaseOrder',
  'ShipmentManagement',
  'cargoManage',
  'WarehousingOrder',
  'OutboundOrder',
  'CreditManage',
  'PaymentManage',
  'CostAdjustment',
  'CostManage',
  'CustManagement',
  'supplierManage',
  'inquiryList',
  'OrderAdd',
  'orderHighList',
  'ShipmentManage',
  'Product List',
  'ProductList',
  'Platform Product',
  'CaroManage',
  'InqueryDemand',
  'PurchaseDemand',
  'PurchaseOrder',
  'InboundSheet',
  'OutboundSheet',
  'order-add',
  'inquiryDemand',
  'OrderSettlement',
  'orderTracking',
  'stockCheck',
  'AllotInquire',
  'outsourceProcess',
  'CBSPayment',
  'BusinessTransfer',
]
// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap
    ?.filter((ale) => ale.routePath != 0)
    ?.map((route) => {
      if (route.componentPath) {
        // Layout ParentView 组件特殊处理
        if (route.componentPath === 'Layout') {
          route.component = Layout
        } else if (route.componentPath === 'ParentView') {
          route.component = { render: (e) => e('router-view') }
        } else {
          route.component = loadView(route.componentPath)
        }
      }
      if (route.children != null && route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children)
      }
      let flag =
        route.children.filter((ele) => ele.routePath != 0 && ele.type !== 2)
          .length > 0
      return {
        title: route.name,
        name: route.englishName ? route.englishName : '',
        path: route.routePath,
        type: route.type,
        component: route.component,
        children: route.children,
        parentId: route.parentId,
        redirect:
          route.children && route.children.length && flag
            ? route.children[0].path
            : '',
        type: route.type,
        isTab:
          route.tab === 0 && route.type === 1
            ? 0
            : route.tab === 1 && route.type === 1
            ? 1
            : 2,
        meta: {
          title: route.name,
          showParent: route.showParent == 0 ? false : true,
          hidden: route.type === 2 ? true : false,
          icon: 'iconfont',
          keepAlive: catchRouteArr.includes(route.englishName) ? true : false,
        },
      }
    })
}

function filterPermissions(arr) {
  // 解决监控报错 Cannot read properties of null (reading 'map')
  if (!Array.isArray(arr)) {
    arr = []
  }
  let permissions = []
  arr.map((ele) => {
    if (ele.type === 2) {
      permissions.push(ele.permission)
    } else {
      if (ele.children && ele.children.length) {
        permissions = permissions.concat(filterPermissions(ele.children))
      }
    }
    if (ele.tab === 1 && ele.type === 1) {
      permissions.push(ele.permission)
    }
  })
  let arrPermissions = [...new Set(permissions)]

  return arrPermissions
}
export const loadView = (view) => {
  // 路由懒加载
  return (resolve) => require([`@/views/${view}.vue`], resolve)
}
