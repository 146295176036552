const translateConfig = {
  zh: {
    inquiryDetails: {
      1: '1',
      Appendix: '附件',
      BasicInformation: '基本信息',
      Cancel: '取消',
      Close: '关闭',
      CompanyName: '公司名称',
      Confirm: '确认',
      ContactNumber: '联系电话',
      CopyOne: '复制一条',
      CostsThat: 'Costs that',
      CreationTime: '创建时间',
      Creator: '创建人',
      CustomerInformation: '客户信息',
      CustomerNameCode: '客户姓名/编码',
      CustomerNotes: 'MKT备注',
      CustomerOrderNumber: '客户订单号',
      CustomerRecord: '客户记录',
      CustomerRepresentative: '客户代表',
      Description: 'Description',
      ExportsRise: '出口抬头',
      InquiryStatus: '询盘状态',
      InquiryDate: '询盘日期',
      InquiryNumber: '询盘单号',
      AssociatedInquiryNumber: '关联询盘单号',
      InquiryQuantity: '询盘数量',
      InquiryRecords: '询盘记录',
      InquirySource: '询盘来源',
      Item: 'Item',
      ItemName: 'Item Name',
      ItemNo: 'Item No.',
      Mail: '邮箱',
      MailRecords: '邮件记录',
      MailTime: '邮件时间',
      NetPrice: 'Net price',
      NumberOfEnquiries: '询盘数',
      Operate: '操作',
      OrderAmount: '订单金额',
      OrderDate: '下单日期',
      OrderNumber: '订单编号',
      OrderRecords: '订单记录',
      OrderStatus: '订单状态',
      OrderSupport: '订单支持',
      OrderVolume: '订单成交量',
      OtherFee: 'Other Fee($)',
      Preview: '预览',
      PrintQuotation: '打印报价单',
      ProductCode: '产品编号',
      ProductEnglishName: '产品英文名称',
      ProductList: '产品列表',
      ProductionTime: 'Production time',
      Qty: 'Qty',
      Quote: '报价',
      QuoteDate: 'Quote Date',
      QuoteNo: 'Quote No.',
      QuoteRecord: '报价记录',
      Recipient: '收件人',
      Remark: '备注',
      Remarks: 'Remarks',
      RequirementDescription: '需求描述',
      Return: '返回',
      SampleTime: 'Sample time',
      SendMailToCustomers: '发送邮件给客户',
      Sender: '发件人',
      SetUpCharge: 'Set up Charge($)',
      ShippingMethod: '运输方式',
      ShippingTime: 'Shipping time',
      Specification: '规格',
      Subtotal: 'Subtotal($)',
      Topic: '主题',
      TotalCost: 'Total Cost',
      TotalOrderAmount: '订单总金额',
      VaildityFor: 'Vaildity for',
      VirtualConfirm: 'Virtual confirm',
      ZipCode: 'Zip code',
      AddQuote: '新增报价',
      EditQuote: '编辑报价',
      CopyQuote: '复制报价',
      FillInInformation: '填写信息',
      RestoreDefault: '恢复默认',
      ManagementProducts: '管理产品',
      PerfectQuote: '完善报价',
      QuoteValid: '报价有效期',
      More: '更多信息>>',
      QuoteQuantity: '报价数量',
      QuoteRemark: '报价备注',
      Trial: '试算',
      Spread: '差价',
      CostPrice: '成本价',
      ExchangeRate: '汇率',
      RateOfProfit: '利润率',
      TariffRate: '关税率',
      Tarif: '关税',
      ShippingFee: '运费单价',
      PringingFee: '印刷单价',
      Product: '产品',
      ProductWeight: '产品重量',
      PrintingArea: '印刷区域',
      WorkingDay: '工作日',
      NaturalDay: '自然日',
      PreviewQuote: '预览报价单',
      SaveDraft: '保存草稿',
      SubmitQuote: '提交报价',
      EBF: '快递基准运费',
      ChargeMode: '计费方式',
      VolumeWeight: '体积重',
      PackingWeight: '装箱重量',
      ChargedWeight: '计费重量',
      PackingVolume: '装箱体积',
      BillingVolume: '计费体积',
      TMVI: '匹配的计价区间',
      Country: '国家',
      ValuationType: '计价类型',
      Scope: '范围',
      Price: '价格',
      Calculate: '计算',
      PrintingBase: '印刷基准单价',
      PrintingStandard: '印刷标准',
      CurrentNnit: '当前印刷单价',
      TheCurrentNumber: '当前数量',
      ViewDetails: '查看详情',
      AddProduct: '添加产品',
      QuoteProductManagement: '报价产品管理',
      SerialNumber: '序号',
      DragTheSorting: '拖动排序',
      quotationnumber: '报价单号',
      Customermailbox: '客户邮箱',
      productcode: '产品编码',
      productname: '产品名称',
      bidder: '报价人',
      status: '状态',
      Downloadquotation: '下载报价单',
      pdffile: 'pdf文件',
      SpecificationMarkup: '规格加价',

      Tips1: '成本价*关税率÷汇率÷利润率÷2',
      Tips2: '（成本价/汇率+运费单价）/利润率+关税',
      Tips3: '（成本价/汇率+运费单价）/利润率+关税+印刷单价',
      Tips4: '体积/5000',
      Tips5: '报价数量/装箱数量*Max（重量，体积重）',
      Tips6: '运费总价/汇率/报价数量',
      Tips7: '系统将计算最新的Net Price，仅做试算对比，不在报价单中展示',
      Tips8: '产品模块中维护的Net Price，会在报价单展示',
      Tips9: '试算Net Price-Sage Net Price',
      Tips10:
        '=（所选计价方式单价-基准单价）*数量，最低印刷单价将作为印刷的基准价',
      Tips11: '（当前印刷单价-基准单价）*数量',
      Tips12: '体积/6000',
      Tips13: '报价数量/装箱数量*装箱重量',
      Tips14: '报价数量/装箱数量*体积',
      Tips15: '报价单中的【Shipping Fee】超过限制条件：超过【Total Amount】的',
      Tips16: '报价单中的【Shipping Fee】超过限制条件：超过$',
      Tips17: '是否继续提交？',
      Tips18: '请修改！',
      Tips19: '请完善区块1中的基本信息',
      Tips20: '请仔细确认报价信息，提交后无法修改！',
      Tips21: '运费总价*计费体积/汇率/报价数量',
      Tips22: '最多输入5条！',
      Tips23: '未开启默认取产品中维护的数据',
      Tips24: '（成本价/汇率+运费单价）/利润率+关税+印刷单价+规格加价',
    },
  },
  en: {
    inquiryDetails: {
      1: '1',
      Appendix: 'Attachment',
      BasicInformation: 'Basic Information',
      Cancel: 'Cancel',
      Close: 'Close',
      CompanyName: 'Company',
      Confirm: 'Confirm',
      ContactNumber: 'Contact Number',
      CopyOne: 'Copy One',
      CostsThat: 'Costs That',
      CreationTime: 'Created Times',
      Creator: 'Creator',
      CustomerInformation: 'Customer Information',
      CustomerNameCode: 'Customer Name/No.',
      CustomerNotes: 'Customer Notes',
      CustomerOrderNumber: 'PO#',
      CustomerRecord: 'Customer Record',
      CustomerRepresentative: 'Sales Rep',
      Description: 'Description',
      ExportsRise: 'RP/RG/SW',
      InquiryStatus: 'Inquiry Status',
      InquiryDate: 'Inquiry Date',
      InquiryNumber: 'Inquiry Number.',
      AssociatedInquiryNumber: 'Associated Inquiry Number',
      InquiryQuantity: 'Inquiry Quantity',
      InquiryRecords: 'Inquiry Records',
      InquirySource: 'Inquiry Source',
      Item: 'Item',
      ItemName: 'Item Name',
      ItemNo: 'Item No.',
      Mail: 'Mail',
      MailRecords: 'Mail Records',
      MailTime: 'Mail Time',
      NetPrice: 'Net price',
      NumberOfEnquiries: 'Number of inquiries',
      Operate: 'Operate',
      OrderAmount: 'Order Amount',
      OrderDate: 'Order Date',
      OrderNumber: 'Order Number',
      OrderRecords: 'Order Records',
      OrderStatus: 'Order Status',
      OrderSupport: 'Order Support',
      OrderVolume: 'Order Turnover',
      OtherFee: 'Other Fee($)',
      Preview: 'Preview',
      PrintQuotation: 'Print Quotation',
      ProductCode: 'Item No.',
      ProductEnglishName: 'Product Item Name',
      ProductList: 'Product List',
      ProductionTime: 'Production Time',
      Qty: 'Qty',
      Quote: 'Quote',
      QuoteDate: 'Quote Date',
      QuoteNo: 'Quote No.',
      QuoteRecord: 'Quote Record',
      Recipient: 'Recipients',
      Remark: 'Remark',
      Remarks: 'Remarks',
      RequirementDescription: 'Description of Requirement',
      Return: 'Return',
      SampleTime: 'Sample Time',
      SendMailToCustomers: 'Send mail to customers',
      Sender: 'Sender',
      SetUpCharge: 'Set up Charge($)',
      ShippingMethod: 'Shipping Method',
      ShippingTime: 'Shipping Time',
      Specification: 'Specification',
      Subtotal: 'Subtotal($)',
      Topic: 'Topic',
      TotalCost: 'Total Cost',
      TotalOrderAmount: 'Total Order Amount',
      VaildityFor: 'Vaildity For',
      VirtualConfirm: 'Virtual Confirm',
      ZipCode: 'Zip Code',
      AddQuote: 'Add Quote',
      EditQuote: 'Edit Quote',
      CopyQuote: 'Copy Quote',
      FillInInformation: 'fill in information',
      RestoreDefault: 'Reset',
      ManagementProducts: 'Products Management ',
      PerfectQuote: 'Improve Quote',
      QuoteValid: 'Quote Valid',
      More: 'More>>',
      QuoteQuantity: 'Quote Quantity',
      QuoteRemark: 'Quote Remark',
      Trial: 'Trial',
      Spread: 'Spread',
      CostPrice: 'Price Differential',
      ExchangeRate: 'Exchange Rate',
      RateOfProfit: 'Profit Rate',
      TariffRate: 'Customs Duty Rate',
      Tarif: 'Customs Duty',
      ShippingFee: 'Shipping Fee',
      PringingFee: 'Pringing Fee',
      Product: 'Product',
      ProductWeight: 'Product Weight',
      PrintingArea: 'Imprint Area',
      WorkingDay: 'Working Day',
      NaturalDay: 'Natural Day',
      PreviewQuote: 'Preview Quote',
      SaveDraft: 'Save Draft',
      SubmitQuote: 'Submit Quote',
      EBF: 'Express Rates',
      ChargeMode: 'Charge Mode',
      VolumeWeight: 'Volume Weight',
      PackingWeight: 'Packing Weight',
      ChargedWeight: 'Charged Weight',
      PackingVolume: 'Packing Volume',
      BillingVolume: 'Billing Volume',
      TMVI: 'The matching valuation interval',
      Country: 'Country',
      ValuationType: 'Valuation Type',
      Scope: 'Scope',
      Price: 'Price',
      Calculate: 'Calculate',
      PrintingBase: 'Printing base unit price',
      PrintingStandard: 'Printing standard',
      CurrentNnit: 'Current unit price of printing',
      TheCurrentNumber: 'The current number',
      ViewDetails: 'View Details',
      AddProduct: 'Add product',
      QuoteProductManagement: 'Quote Product Management',
      SerialNumber: 'No.',
      DragTheSorting: 'Drag',
      quotationnumber: 'quotation No.',
      Customermailbox: 'Customer Email',
      productcode: 'Item Number',
      productname: 'product name',
      bidder: 'bidder',
      status: 'status',
      Downloadquotation: 'Download quotation',
      pdffile: 'pdf file',
      SpecificationMarkup: 'Specification Markup',

      Tips1: 'Cost price * Tariff rate ÷ Exchange Rate ÷ Profit Rate ÷2',
      Tips2: '(Cost price/Exchange Rate + Shipping Fee)/Profit Rate + Tariff',
      Tips3:
        '(Cost price/Exchange Rate + Shipping Fee)/Profit Rate + Tariff + printing unit price',
      Tips4: 'The volume / 5000',
      Tips5: 'Quoted quantity/packing quantity *Max (weight, volume weight)',
      Tips6: 'The freight price/Exchange Rate/quoted quantity',
      Tips7:
        'The exchange rate system will calculate the latest Net Price, which is only for trial comparison and not shown in the quotation sheet ',
      Tips8:
        'The Net Price maintained in the product module will be displayed in the quotation sheet',
      Tips9: 'Trial Net Price-Sage Net Price',
      Tips10:
        '=Selected pricing method unit price - base unit price) * Quantity, the lowest printing unit price will be used as the base price of printing ',
      Tips11: '(Current printing unit price - base unit price) * Quantity',
      Tips12: 'The volume / 6000',
      Tips13: 'Quoted quantity/packing quantity * packing weight',
      Tips14: 'Quoted quantity/packing quantity * volume',
      Tips15:
        '[Shipping Fee] in quotation exceeds limit: exceeds [Total Amount] ',
      Tips16:
        'The [Shipping Fee] in the quotation exceeds the limitation: exceeds $',
      Tips17: 'Whether to continue submission ?',
      Tips18: 'please revise!',
      Tips19: 'Please improve the basic information in block 1',
      Tips20:
        'Please confirm the quotation information carefully. It cannot be modified after submission!',
      Tips21:
        'The freight price * Billing volume/Exchange Rate/quoted quantity',
      Tips22: 'A maximum of five entries can be entered!',
      Tips23: 'Default fetching data maintained in the product is not enabled',
      Tips24:
        '(Cost price/Exchange Rate + Shipping Fee)/Profit Rate + Tariff + printing unit price + Specification markup',
    },
  },
}

export default translateConfig
