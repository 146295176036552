import Vue from 'vue'
import request from '@/core/services/request'

// 报价提醒保存
export function orderRemindSave(data) {
  return request({
    url: '/order/orderRemind/insertPO',
    method: 'post',
    data,
  })
}

// 报价提醒获取
export function orderRemindDetail() {
  return request({
    url: '/order/orderRemind/getDetail',
    method: 'post',
  })
}

// 生成的报价单作废
export function checkPrintSheet(data) {
  return request({
    url: '/order/orderEnquiry/checkPrintSheet',
    method: 'post',
    data,
  })
}

// 编辑根据id查找
export function orderEnquiryDetail(data) {
  return request({
    url: '/order/orderEnquiry/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据id查看
export function orderEnquiryLookDetail(data) {
  return request({
    url: '/order/orderEnquiry/getLookDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据分页查找列表
export function orderEnquiryList(data) {
  return request({
    url: '/order/orderEnquiry/listPage',
    method: 'post',
    data,
  })
}

// 询盘操作
export function orderEnquiryStatus(data) {
  return request({
    url: '/order/orderEnquiry/updateStatus',
    method: 'post',
    data,
  })
}

// 询盘新增获取出口抬头和询盘来源
export function orderGetDict(data) {
  return request({
    url: '/order/orderAmount/listPage',
    method: 'post',
    data,
  })
}

// 分页列表查询全部客户
export function selectAllCustomer(data) {
  return request({
    url: '/merchants/info/selectAllCustomer',
    method: 'post',
    data,
  })
}

export function selectAllCustomerNoAuth(data) {
  return request({
    url: '/merchants/info/selectAllCustomerNoAuth',
    method: 'post',
    data,
  })
}

// 批量打印报价单
export function printOrderPriceSheet(data) {
  return request({
    url: '/order/orderEnquiry/printOrderPriceSheet',
    method: 'post',
    data,
  })
}

// 新增询盘单
export function orderEnquiryAdd(data) {
  return request({
    url: '/order/orderEnquiry/insertPO',
    method: 'post',
    data,
  })
}
// 克隆询盘单
export function enquiryClone(data) {
  return request({
    url: '/order/orderEnquiry/enquiryClone',
    method: 'post',
    data,
  })
}

// 更新询盘单
export function orderEnquiryUpdate(data) {
  return request({
    url: '/order/orderEnquiry/updatePO',
    method: 'post',
    data,
  })
}

// 根据询盘id查找报价单号下拉框
export function orderPriceSheetCode(data) {
  return request({
    url: '/order/orderPriceSheet/listPriceSheetCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function orderPriceSheetCodeNew(data) {
  return request({
    url: '/order/orderPriceSheet/listPriceSheetCodeNew',
    method: 'post',
    data,
  })
}

// 客户邮箱查询汇总信息
export function orderListEmail(data) {
  return request({
    url: '/order/orderEnquiry/listEmail',
    method: 'post',
    data,
  })
}

// 客户邮箱分页查找询盘列表
export function listEmailEnquiryPage(data) {
  return request({
    url: '/order/orderEnquiry/listEmailEnquiryPage',
    method: 'post',
    data,
  })
}

// 客户邮箱分页查找订单列表
export function listEnquiryOderVOPage(data) {
  return request({
    url: '/order/orderEnquiry/listEnquiryOderVOPage',
    method: 'post',
    data,
  })
}

// 客户邮箱分页查找邮件记录
export function emailPageList(data) {
  return request({
    url: '/system/email/pageList',
    method: 'post',
    data,
  })
}

// 报价
export function orderPriceSheet(data) {
  return request({
    url: '/order/orderEnquiry/orderPriceSheet',
    method: 'post',
    data,
  })
}

// 新增，生成报价单
export function orderPriceSheetInit(data) {
  return request({
    url: '/order/orderEnquiry/orderPriceSheetInit',
    method: 'post',
    data,
  })
}

// 新增报价实体
export function orderInsertPO(data) {
  return request({
    url: '/order/orderPriceSheet/insertPO',
    method: 'post',
    data,
  })
}

// 发起询价
export function toInquiry(data) {
  return request({
    url: '/order/orderEnquiry/toInquiry',
    method: 'post',
    data,
  })
}

// 查看询价详情
export function listInquiryDetailByEnquiryId(data) {
  return request({
    url: '/order/orderEnquiry/listInquiryDetailByEnquiryId',
    method: 'post',
    data,
  })
}

// 询价单-分配处理人
export function assignHandler(data) {
  return request({
    url: '/order/inquiryOrder/assignHandler',
    method: 'post',
    data,
  })
}

// 询价单-编辑回显
export function inquiryGetDetailForEdit(data) {
  return request({
    url: '/order/inquiryOrder/getDetailForEdit',
    method: 'post',
    data,
  })
}

// 询价单-查看详情
export function inquiryGetDetailForShow(data) {
  return request({
    url: '/order/inquiryOrder/getDetailForShow',
    method: 'post',
    data,
  })
}

// 询价单-处理
export function inquiryHandle(data) {
  return request({
    url: '/order/inquiryOrder/handle',
    method: 'post',
    data,
  })
}

// 询价单-新增保存
export function insertBatch(data) {
  return request({
    url: '/order/inquiryOrder/insertBatch',
    method: 'post',
    data,
  })
}

// 询价单-根据分页查找列表
export function inquiryListPage(data) {
  return request({
    url: '/order/inquiryOrder/listPage',
    method: 'post',
    data,
  })
}

// 打印报价单
export function orderPrintSheet(data) {
  return request({
    url: '/order/orderEnquiry/printSheet',
    method: 'post',
    data,
  })
}

// 报价单详情
export function getPriceSheetDetail(params) {
  return request({
    url: '/order/orderPriceSheet/getDetail',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 报价单克隆
export function getPriceSheetClone(params) {
  return request({
    url: '/order/orderPriceSheet/clone',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 报价单作废
export function getPriceToVoid(params) {
  return request({
    url: '/order/orderPriceSheet/toVoid',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 询盘单查询销售订单数据
export function enquiryProduceOrder(data) {
  return request({
    url: '/order/orderEnquiry/enquiryProduceOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 订单接口详情
 */
// 根据收货地址查看产品分配数量
export function orderAddressProduct(data) {
  return request({
    url: '/order/salesOrder/addressPurchasingProductVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 订单接口详情
 */
// 根据收货地址查看产品分配数量
export function addressProduct(data) {
  return request({
    url: '/order/salesOrder/addressProduct',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 保存安排订单或批量安排订单
export function orderAlignOrderList(data) {
  return request({
    url: '/order/salesOrder/alignOrderList',
    method: 'post',
    data,
  })
}

// 主页安排订单
export function orderAlignOrderShow(data) {
  return request({
    url: '/order/salesOrder/alignOrderShow',
    method: 'post',
    data,
  })
}

// 克隆
export function orderCloneDetail(data) {
  return request({
    url: '/order/salesOrder/cloneDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 编辑回显
export function orderGetDetail(data) {
  if (data?.orderId) {
    return request({
      url: '/order/salesOrder/getDetail',
      method: 'post',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
  } else {
    Vue.prototype.$baseMessage(
      'orderId is empty',
      'warning',
      false,
      'erp-hey-message-warning'
    )
    return Promise.reject('')
  }
}

// 根据订单号回显订单信息
export function orderGetDetailByOrderCode(data) {
  return request({
    url: '/order/salesOrder/getDetailByOrderCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据订单号回显订单信息
export function orderNewGetDetailByOrderCode(data) {
  return request({
    url: '/order/salesOrder/newGetDetailByOrderCodeEncode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

// 设置箱唛
export function orderInsertMark(data) {
  return request({
    url: '/order/salesOrder/insertMark',
    method: 'post',
    data,
  })
}

// 新增实体
export function orderSalesInsertPO(data) {
  return request({
    url: '/order/salesOrder/insertPO',
    method: 'post',
    data,
  })
}

// 报价单下拉框
export function orderListOrderPriceSheetCode(data) {
  return request({
    url: '/order/salesOrder/listOrderPriceSheetCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据分页查找列表
export function orderListPage(data) {
  return request({
    url: '/order/salesOrder/listPageEncode',
    method: 'post',
    data,
    headers: {
      encrypt: true,
    },
  })
}

// 根据分页查找列表-简化
export function easyOrderListPage(data) {
  return request({
    url: '/order/orderProduct/easyOrderListPage',
    method: 'post',
    data,
    headers: {
      encrypt: true,
    },
  })
}

// 打印
export function orderPrintOrder(data) {
  return request({
    url: '/order/salesOrder/printOrder',
    method: 'post',
    data,
  })
}

// 根据报价单号生成订单
export function orderSaleOrderByPriceSheetCode(data) {
  return request({
    url: '/order/salesOrder/saleOrderByPriceSheetCode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 出库出运信息
export function orderShipInfo(data) {
  return request({
    url: '/order/salesOrder/shipInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 箱唛编辑回显
export function orderShowMark(data) {
  return request({
    url: '/order/salesOrder/showMark',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增订单保存
export function salesOrderSave(data) {
  return request({
    url: '/order/salesOrder/savePO',
    method: 'post',
    data,
  })
}

// 更新实体
export function orderUpdatePO(data) {
  return request({
    url: '/order/salesOrder/updatePO',
    method: 'post',
    data,
  })
}

// 更改图片状态
export function orderUpdateReding(data) {
  return request({
    url: '/order/salesOrder/updateReding',
    method: 'post',
    data,
  })
}

// 货运方式下拉框接口
export function orderShipType() {
  return request({
    url: '/product/freightSetting/selectAll',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 作废
export function orderUpdateCancellation(data) {
  return request({
    url: '/order/salesOrder/updateCancellation',
    method: 'post',
    data,
  })
}

// 进程
export function orderCourse(data) {
  return request({
    url: '/order/salesOrder/orderCourse',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单管理查找地址列表
export function merchantsAddressConfigListPage(data) {
  return request({
    url: '/merchants/receivingAddressConfig/listPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 采购管理
 */
// (采购订单)审核回调
export function purchaseAuditCallback(data) {
  return request({
    url: '/order/purchaseOrder/auditCallback',
    method: 'post',
    data,
  })
}

// (采购订单)查找详情（用于编辑回显）
export function purchaseGetDetailForEdit(data) {
  return request({
    url: '/order/purchaseOrder/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (采购订单)查找详情（用于查看回显）
// export function purchaseGetDetailForShow(data) {
//   return request({
//     url: '/order/purchaseOrder/getDetailForShow',
//     method: 'post',
//     data,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//     },
//   })
// }

export function purchaseGetDetailForShow(data) {
  return request({
    url: '/order/purchaseOrder/getDetailForShowEncode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

// (采购订单)通过采购单号查找详情
export function purchaseGetDetailForShowByNumber(data) {
  return request({
    url: '/order/purchaseOrder/getDetailForShowByNumber',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (采购订单)新增实体
export function purchaseInsertPO(data) {
  return request({
    url: '/order/purchaseOrder/insertPO',
    method: 'post',
    data,
  })
}

// (采购订单)分页列表查询
export function purchaseListPage(data) {
  return request({
    url: '/order/purchaseOrder/listPageEncode',
    method: 'post',
    data,
    headers: {
      encrypt: true,
    },
  })
}

// (采购订单)分页列表查询加密
// export function purchaseListPage(data) {
//   return request({
//     url: '/order/purchaseOrder/listPage',
//     method: 'post',
//     data,
//   })
// }

// (采购订单)作废
export function purchaseLogicDeleteByIds(data) {
  return request({
    url: '/order/purchaseOrder/logicDeleteByIds',
    method: 'post',
    data,
  })
}

// (采购订单)更新实体
export function purchaseUpdatePO(data) {
  return request({
    url: '/order/purchaseOrder/updatePO',
    method: 'post',
    data,
  })
}

// (采购需求)审核回调
export function purchasingDAuditCallback(data) {
  return request({
    url: '/order/purchasingDemand/auditCallback',
    method: 'post',
    data,
  })
}

// (采购需求)查找详情（用于编辑回显）
export function purchasingDGetDetailForEdit(data) {
  return request({
    url: '/order/purchasingDemand/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(采购需求)查找详情（用于查看回显）
export function purchasingDGetDetailForShow(data) {
  return request({
    url: '/order/purchasingDemand/getDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(采购需求)采购开发新增
export function purchasingDInsertByBuyer(data) {
  return request({
    url: '/order/purchasingDemand/insertByBuyer',
    method: 'post',
    data,
  })
}

//(采购需求)更新实体
export function purchasingDUpdatePO(data) {
  return request({
    url: '/order/purchasingDemand/updatePO',
    method: 'post',
    data,
  })
}

//(采购需求)克隆
export function purchasingDClone(data) {
  return request({
    url: '/order/purchasingDemandProduct/clone',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(采购需求产品)生成采购订单
export function purchasingDCreatePurchaseOrder(data) {
  return request({
    url: '/order/purchasingDemandProduct/createPurchaseOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(采购需求产品)分页列表查询
export function purchasingDListPage(data) {
  return request({
    url: '/order/purchasingDemandProduct/listPage',
    method: 'post',
    data,
  })
}

//(验货单)查找详情（用于编辑回显）
export function inspectionGetDetailForEdit(data) {
  return request({
    url: '/order/inspectionOrder/getDetailForEdit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(验货单)查找详情（用于重新验货回显）
export function getResubDetailForShow(data) {
  return request({
    url: '/order/inspectionOrder/getResubDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(验货单)查找详情（用于查看回显）
export function inspectionGetDetailForShow(data) {
  return request({
    url: '/order/inspectionOrder/getDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(验货单)新增实体
export function inspectionInsertPO(data) {
  return request({
    url: '/order/inspectionOrder/insertPO',
    method: 'post',
    data,
  })
}

//(验货单)分页列表查询
export function inspectionListPage(data) {
  return request({
    url: '/order/inspectionOrder/listPage',
    method: 'post',
    data,
  })
}

//(验货单)设置验货员
export function inspectionUpdateInspector(data) {
  return request({
    url: '/order/inspectionOrder/updateInspector',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//(验货单)更新实体
export function inspectionUpdatePO(data) {
  return request({
    url: '/order/inspectionOrder/updatePO',
    method: 'post',
    data,
  })
}

//(入库申请单)新增实体
export function applicationWarehouseInInsertPO(data) {
  return request({
    url: '/order/applicationWarehouseIn/insertPO',
    method: 'post',
    data,
  })
}

// (采购订单)不合格品处理
export function orderDisposalUnqualified(data) {
  return request({
    url: '/order/purchaseOrder/disposalUnqualified',
    method: 'post',
    data,
  })
}

// 获取供应商名称
export function supplierListForSelect(data) {
  return request({
    url: '/merchants/supplier/listForSelect',
    method: 'post',
    data,
  })
}

// 选择供应商查找信息
export function supplierGetDetail(data) {
  return request({
    url: '/merchants/supplier/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 采购订单查看订单信息
export function orderGetPurchasingOrderVO(data) {
  return request({
    url: '/order/salesOrder/getPurchasingOrderVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (出库申请单)新增实体
export function orderApplicationWarehouseOut(data) {
  return request({
    url: '/order/applicationWarehouseOut/insertPO',
    method: 'post',
    data,
  })
}

// 运输方式列表
export function storageShippingMethodList(data) {
  return request({
    url: '/storage/shippingOrder/shippingMethodList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 贸易条款列表
export function storageTradeList(data) {
  return request({
    url: '/storage/shippingOrder/tradeList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 交货方式列表
export function storageDeliveryModeList(data) {
  return request({
    url: '/storage/shippingOrder/deliveryModeList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 交货仓库地址列表
export function storageDeliveryAddressList(data) {
  return request({
    url: '/storage/shippingOrder/deliveryAddressList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增货运委托单
export function storageInsertPO(data) {
  return request({
    url: '/storage/shippingOrder/insertPO',
    method: 'post',
    data,
  })
}

// (采购需求产品)生成出库申请
export function orderCreateWarehouseOutOrder(data) {
  return request({
    url: '/order/purchasingDemandProduct/createWarehouseOutOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (采购订单)生成付款申请
export function orderCreatePayOrder(data) {
  return request({
    url: '/order/purchaseOrder/createPayOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (付款申请)新增实体
export function orderApplicationPayOrderInsertPO(data) {
  return request({
    url: '/order/applicationPayOrder/insertPO',
    method: 'post',
    data,
  })
}

// (采购订单)发票登记
// export function orderInvoiceRegistration(data) {
//   return request({
//     url: '/order/purchaseOrder/invoiceRegistration',
//     method: 'post',
//     data,
//   })
// }

export function orderInvoiceRegistration(data) {
  return request({
    url: '/order/purchaseOrder/invoiceRegistrationDecode',
    method: 'post',
    data,
    headers: {
      encode: true,
    },
  })
}

// (采购订单)发票信息编辑回显
// export function orderListInvoiceDetail(data) {
//   return request({
//     url: '/order/purchaseOrder/listInvoiceDetail',
//     method: 'post',
//     data,
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
//     },
//   })
// }

// 加密版
export function orderListInvoiceDetail(data) {
  return request({
    url: '/order/purchaseOrder/listInvoiceDetailEncode',
    method: 'post',
    data,
    headers: {
      encrypt: true,
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 汇率转换
export function financeConversionExchangeRate(data) {
  return request({
    url: '/finance/costSheet/conversionExchangeRate',
    method: 'post',
    data,
  })
}

// 汇率转换比例
export function financeGetCurrency(data) {
  return request({
    url: '/finance/finance/getCurrency',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//
export function financeGetCurrencyBySelectTime(data) {
  return request({
    url: '/finance/finance/selectCurrencyBySelectTime',
    method: 'post',
    data,
  })
}

// 订单采购管理下拉仓库
export function storageFindWarehouseList2(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data,
  })
}

// 分配业务员
export function updateSale(data) {
  return request({
    url: '/order/orderEnquiry/updateSale',
    method: 'post',
    data,
  })
}

// 出库单--出运中新增出库
export function insertWarehouseOutByShipping(data) {
  return request({
    url: '/storage/warehouseOut/insertWarehouseOutByShipping',
    method: 'post',
    data,
  })
}

// (采购订单)发票作废
export function invoiceDelete(data) {
  return request({
    url: '/order/purchaseOrder/invoiceDelete',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 获取出运对象
export function getListEntity(data) {
  return request({
    url: '/storage/shippingOrder/getListEntity',
    method: 'post',
    data,
  })
}

// 作废页面信息
export function cancellationPage(data) {
  return request({
    url: '/order/salesOrder/cancellationPage',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 在线支付弹窗
export function orderPayOnline(data) {
  return request({
    url: '/order/paymentLink/getPayOnlineEncode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

// 生成支付链接
export function orderGetLink(data) {
  return request({
    url: '/order/paymentLink/getLinkDecode',
    method: 'post',
    data,
  })
}

// 订单新增预览合同
export function orderPreviewOrder(data) {
  return request({
    url: '/order/salesOrder/previewOrder',
    method: 'post',
    data,
  })
}

// (付款申请)审核回调
export function orderAuditCallback(data) {
  return request({
    url: '/order/refundOrder/auditCallback',
    method: 'post',
    data,
  })
}

// 获取售后订单审核详情
export function orderGetAuditDetail(data) {
  return request({
    url: '/order/refundOrder/getAuditDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据id查找售后
export function refundOrderGetDetail(data) {
  return request({
    url: '/order/refundOrder/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增实体
export function refundOrderInsertPO(data) {
  return request({
    url: '/order/refundOrder/insertPO',
    method: 'post',
    data,
  })
}

// 提交销售付款申请
export function machiningApplicationPay(data) {
  return request({
    url: '/order/refundOrder/machiningApplicationPay',
    method: 'post',
    data,
  })
}

// 售后单状态更新
export function refundOrderProcessing(data) {
  return request({
    url: '/order/refundOrder/refundOrderProcessing',
    method: 'post',
    data,
  })
}

// 批量删除销售订单
export function orderBatchDelete(data) {
  return request({
    url: '/order/salesOrder/batchDelete',
    method: 'post',
    data,
  })
}

// 生成采购单判断能否编辑
export function orderWhetherEditor(data) {
  return request({
    url: '/order/salesOrder/whetherEditor',
    method: 'post',
    data,
  })
}

// (采购需求产品)修改采购开发
export function updatePurchasingDemandBuyer(data) {
  return request({
    url: '/order/purchasingDemandProduct/updatePurchasingDemandBuyer',
    method: 'post',
    data,
  })
}

// 校验采购需求有没有被处理
export function purchasingDemandVerify(data) {
  return request({
    url: '/order/salesOrder/purchasingDemandVerify',
    method: 'post',
    data,
  })
}

// (采购订单)采购详情唛头导出
export function exportPurchaseMarksWord(data) {
  return request({
    url: '/order/purchaseOrder/exportPurchaseMarksWord',
    method: 'post',
    data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (采购需求产品)加工完成
export function processingCompleted(data) {
  return request({
    url: '/order/purchasingDemandProduct/processingCompleted',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 采购需求撤销出库单数据
export function revokeWarehouseOut(data) {
  return request({
    url: '/order/purchasingDemandProduct/revokeWarehouseOut',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 入库单--采购需求入库操作
export function warehouseInFromWarehouseOutInfo(data) {
  return request({
    url: '/storage/warehouseIn/warehouseInFromWarehouseOutInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// (采购订单)查看当前付款情况
export function listPaymentMethod(data) {
  return request({
    url: '/order/purchaseOrder/listPaymentMethod',
    method: 'post',
    data,
  })
}
// 销售订单导出全部
export function exportSalesOrdersAll(data) {
  return request({
    url: '/order/salesOrder/exportOrdersAll',
    method: 'post',
    data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 销售订单导出选中
export function exportSalesOrdersSelect(data) {
  return request({
    url: '/order/salesOrder/exportOrdersSelect',
    method: 'post',
    data,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 销售订单详情，出运查看
export function shippingPlanByOrderNumber(data) {
  return request({
    url: '/storage/shippingPlan/v2/listByOrderNumber',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 销售订单详情，出运计划更新
export function shippingPlanUpdate(data) {
  return request({
    url: '/storage/shippingPlan/v2/update',
    method: 'post',
    data,
  })
}

export function orderCostApply(data) {
  return request({
    url: '/finance/costSheet/insertPODecode',
    method: 'post',
    data,
    headers: {
      encode: true,
    },
  })
}

// 采购单-费用申请前置判断
export function preExpenseApplication(data) {
  return request({
    url: '/order/purchaseOrder/preExpenseApplication',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 采购单审核时根据ID查询业务员邮箱信息
export function listSendMailInfo(data) {
  return request({
    url: '/order/purchaseOrder/listSendMailInfo',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 采购单-锁定库存预查询
export function listPurchaseStockLockPre(data) {
  return request({
    url: '/order/purchaseOrder/listPurchaseStockLockPre',
    method: 'post',
    data,
  })
}

// 采购单-新增锁定库存
export function stockLockRecordInsertPO(data) {
  return request({
    url: '/storage/stockLockRecord/insertPO',
    method: 'post',
    data,
  })
}

// 采购单-根据仓库id和模块查询库存设置的值
export function getWarehouseSettingValue(data) {
  return request({
    url: '/storage/warehouseSetting/getWarehouseSettingValue',
    method: 'post',
    data,
  })
}

// 采购单-查询库存锁定量
export function listStockLockInfo(data) {
  return request({
    url: '/storage/stockLockRecord/listStockLockInfo',
    method: 'post',
    data,
  })
}

// 采购单-解锁
export function stockLockRecordUnlock(data) {
  return request({
    url: '/storage/stockLockRecord/unLock',
    method: 'post',
    data,
  })
}

// 销售订单-查看费用分摊情况
export function getOederCostDetail(data) {
  return request({
    url: '/order/salesOrder/getOederCostDetail',
    method: 'post',
    data,
  })
}

// 采购单-入库异常处理
export function warehousingExceptionHandling(data) {
  return request({
    url: '/order/purchaseOrder/warehousingExceptionHandling',
    method: 'post',
    data,
  })
}

// 采购单-上传盖章合同
export function addSealedContract(data) {
  return request({
    url: '/order/purchaseOrder/addSealedContract',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 采购单-列表处理异常拉取数据
export function handleExceptionPre(data) {
  return request({
    url: '/order/purchaseOrder/handleExceptionPre',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 询盘单-批量上传
export function enquiryImport(data) {
  return request({
    url: '/order/orderEnquiry/enquiryImport',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8',
    },
  })
}

// 上传付款凭证
export function uploadReceiptRegistration(data) {
  return request({
    url: '/order/salesOrder/uploadReceiptRegistration',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单合同-生成pdf
export function orderpdf(data) {
  return request({
    url: '/order/salesOrder/orderpdf',
    method: 'post',
    data,
  })
}

// 撤销订单安排
export function revocationAlignOrder(data) {
  return request({
    url: '/order/salesOrder/revocationAlignOrder',
    method: 'post',
    data,
  })
}

// 获取销售订单发票详情
export function getOrderInvoice(orderId) {
  return request({
    url: '/order/salesOrder/getOrderInvoice',
    method: 'post',
    params: {
      orderId,
    },
  })
}

// 是否生成发票
export function checkOrderInvoice(orderId) {
  return request({
    url: '/order/salesOrder/checkOrderInvoice',
    method: 'post',
    params: {
      orderId,
    },
  })
}

// 修改采购订单页面的效果图
export function editBusinessImg(data) {
  return request({
    url: '/order/purchaseOrder/updatePrintingImg',
    method: 'post',
    data,
  })
}

// 更新订单支持人员
export function upFromBusinessUsingPOST(data = {}) {
  return request({
    url: '/order/salesOrder/UpFromBusiness',
    method: 'post',
    data,
  })
}
// 更新采购单产品修改状态
export function updateRenderingStatusUsingPOST(data = {}) {
  return request({
    url: '/order/purchaseOrder/updateRenderingStatus',
    method: 'post',
    data,
  })
}
// 更新采购需求单产品修改状态
export function updateRenderingProductStatusUsingPOST(data = {}) {
  return request({
    url: '/order/purchasingDemandProduct/updateRenderingStatus',
    method: 'post',
    data,
  })
}
// 产品详情 bom单
export function productBomData(data = {}) {
  return request({
    url: '/order/bomProduct/getBomProductVOForProduct',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// bom单拆分
export function bomProductSplit(data) {
  return request({
    url: '/order/bomProduct/split',
    method: 'post',
    data,
  })
}
// bom单取消拆分
export function cancelSplit(data) {
  return request({
    url: '/order/bomProduct/cancelSplit',
    method: 'post',
    data,
  })
}
// bom单更新实体
export function bomProductUpdatePO(data) {
  return request({
    url: '/order/bomProduct/updatePO',
    method: 'post',
    data,
  })
}
// 采购需求列表查询子产品
export function getPurchasingChildList(data) {
  return request({
    url: '/order/purchasingDemandProduct/childList',
    method: 'post',
    data,
  })
}
// 辅料查询历史数量
export function getPurchasingDemandProductChildByList(data) {
  return request({
    url: '/order/purchasingDemandProduct/getPurchasingDemandProductChildByList',
    method: 'post',
    data,
  })
}

// 通过子产品查父产品  或  通过父产品查找子产品
export function getShippingPurchesList(data) {
  return request({
    url: '/order/purchasingDemand/getShippingPurches',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 生成发票获取dueAmount
export function selectFinanceInvoice(data) {
  return request({
    url: '/finance/finance/selectFinanceInvoice',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 生成简易发票获取dueAmount
export function selectFinanceSimpleInvoice(data) {
  return request({
    url: '/finance/finance/selectFinanceSimpleInvoice',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 根据infoid查找最后一次收款（出）信息
export function getFinanceDetailByInfoId(data) {
  return request({
    url: '/finance/finance/getFinanceDetailByInfoId',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * (采购订单)编辑QC
 */
export function updateQC(data = {}) {
  return request({
    url: '/order/purchaseOrder/updateQC',
    method: 'post',
    data,
  })
}

// 校验订单手动完结
export function checkOrderEnd(data = {}) {
  return request({
    url: '/order/salesOrder/checkManualOrderCompletion',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 订单手动完结
export function manualEnd(data = {}) {
  return request({
    url: '/order/salesOrder/manualOrderCompletion',
    method: 'post',
    data,
  })
}

// 获取销售订单产品收入成本利润
export function costProfit(data) {
  return request({
    url: '/order/salesOrder/costProfit',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

// 询盘询价编辑修改保存
export function updateInquiryOrder(data) {
  return request({
    url: '/order/inquiryOrder/updateInquiryOrder',
    method: 'post',
    data,
  })
}

// 删除询盘列表
export function deletePO(data) {
  return request({
    url: '/order/orderEnquiry/deletePO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 询盘保存
export function saveUpdatePO(data) {
  return request({
    url: '/order/orderEnquiry/saveUpdatePO',
    method: 'post',
    data,
  })
}
// 分配印刷文件
export function upPrintedDocuments(data) {
  return request({
    url: 'order/salesOrder/UpPrintedDocuments',
    method: 'post',
    data,
  })
}

// 获取采购合同甲方数据
export function contractData(data) {
  return request({
    url: 'order/buyerCompany/listAll',
    method: 'post',
    data,
  })
}

// 订单新增编辑选择收货地址校验黑名单
export function checkBlackReceivingCompanyAndAddress(data) {
  return request({
    url: '/order/salesOrder/checkBlackReceivingCompanyAndAddressReturn',
    method: 'post',
    data,
  })
}

/**
 * 获取进度关联的跟踪单号
 */
export function getTrackingNos(orderId, methodType) {
  const url = `/order/orderProduct/${
      methodType == 'ORDER'
        ? 'getOrderTrackingProductInfoByOrderId'
        : 'selectOrderTrackingProductPurchaseList'
    }`,
    data = {
      [methodType == 'ORDER' ? 'orderId' : 'purchaseOrderId']: orderId,
    }
  return request({
    url,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function getTrackingDetail(orderTrackingId) {
  return request({
    url: '/order/orderTracking/getDetailForEdit',
    method: 'post',
    data: {
      orderTrackingId,
      checkFlag: 0,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function updateTrackingPO(data = {}) {
  return request({
    url: '/order/orderTracking/updatePO',
    method: 'post',
    data,
  })
}

export function queryNodes(orderTrackingId) {
  return request({
    url: '/order/orderTrackingNode/queryNodes',
    method: 'post',
    data: {
      orderTrackingId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function querySealedContract(numbers) {
  return request({
    url: '/order/purchaseOrder/querySealedContract',
    method: 'post',
    data: {
      numbers,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function queryNodesc(orderTrackingId) {
  return request({
    url: '/order/orderTrackingRemark/queryRemarks',
    method: 'post',
    data: {
      orderTrackingId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

export function queryNodeLog(bizId, pageLe, pageNo) {
  return request({
    url: '/system/operationLog/listPage',
    method: 'post',
    data: {
      bizId,
      pageLe,
      pageNo,
    },
  })
}

// 产品库存批次查询（采购需求出库）
export function lotRecommend(data) {
  return request({
    url: '/storage/stock/lotRecommend',
    method: 'post',
    data,
  })
}

// 采购需求出库申请-获取仓库配置
export function getStockCostConfigureVO(data) {
  return request({
    url: '/storage/costConfigure/getStockCostConfigureVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 更新销售订单备注
export function updateOrderRemark(data) {
  return request({
    url: '/order/orderProduct/updateOrderRemark',
    method: 'post',
    data,
  })
}

// 判断销售订单产品是否产生费用
export function checkDeleteOrderProduct(data) {
  return request({
    url: '/finance/cost/checkDeleteOrderProduct',
    method: 'post',
    data,
  })
}

// 判断采购订单产品是否产生费用
export function checkDeletePurchaseProduct(data) {
  return request({
    url: '/finance/cost/checkDeletePurchaseProduct',
    method: 'post',
    data,
  })
}

// 判断订单保存提交有没有关联锁定库存
export function checkOrderStockLock(data) {
  return request({
    url: '/order/orderProduct/checkOrderStockLock',
    method: 'post',
    data,
  })
}

export function productPage(data) {
  return request({
    url: '/storage/shippingOrder/v2/applyOutStock/product',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增订单历史问题备注
export function updateHistoryRemark(data) {
  return request({
    url: '/order/orderProduct/updateHistoryRemark',
    method: 'post',
    data,
  })
}

export function operationLogListPage(bizId) {
  return request({
    url: '/system/operationLog/listPage',
    method: 'post',
    data: {
      bizId,
      pageLe: 1000,
      pageNo: 1,
    },
  })
}

export async function getTabInfo({ orderTrackingId, index }) {
  const index2ReqMethod = {
    1: getTrackingDetail,
    2: operationLogListPage,
    3: queryNodesc,
  }
  const res = await index2ReqMethod[index](orderTrackingId).catch((err) => {
    throw err
  })
  if (res?.code === '000000') {
    const handledData = _handleData(index, res)
    return {
      list: index == 1 ? handledData.list : handledData,
      detail: index === 1 ? res.data : {},
      specialNodeList: index == 1 ? handledData.specialNodeList : [],
      beforeNodes: index == 1 ? handledData.beforeNodes : {},
    }
  } else {
    return []
  }
}
export const SPECIAL_NODES = [
  'QC',
  'LOGISTICS_FREIGHT',
  'THE_CUSTOMER_TO_SIGN_FOR',
]
function _handleNodes(list) {
  let beforeNodes = {}
  let initNodeList = list.slice()
  let specialNodeList = []
  if (list.length === 1) {
    list[0].disabled = false
  } else {
    list = list.map((i) => {
      if (i.realDate && i.dueDate) {
        i.deferredDays = _getDeferredDays(i)
      }
      if (SPECIAL_NODES.includes(i.nodeType)) {
        i.specialNode = true
      } else {
        i.specialNode = false
      }
      return i
    })
    specialNodeList = initNodeList.filter((i) =>
      SPECIAL_NODES.includes(i.nodeType)
    )
    let simpleNodes = list.filter((item) => !item.specialNode)
    for (let i = 1; i < simpleNodes.length; i++) {
      if (simpleNodes[i].realDate) {
        simpleNodes[i - 1].disabled = true
      } else {
        simpleNodes[i - 1].disabled = false
      }
    }
    for (let i = 0; i < simpleNodes.length; i++) {
      // 处理最后一个节点
      if (i == 0) {
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']] = {}
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']].before = {
          realDate: 'xxxx',
        }
      } else {
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']] = {}
        beforeNodes[simpleNodes[i]['orderTrackingNodeId']].before =
          simpleNodes[i - 1]
      }
    }
    if (list.length === 1) {
      list[0].disabled = false
    }
    list.forEach((item, index, arr) => {
      if (item.status == 3 || status == 4 || status == 7) {
        item.disabled = true
      }
    })
  }
  // return list.reverse()
  return {
    list: list.reverse(),
    specialNodeList,
    beforeNodes,
  }
}

/**
 * 跟单节点单个保存
 */

export function updateSingleNode(data = {}) {
  return request({
    url: '/order/orderTrackingNode/updatePO',
    method: 'post',
    data,
  })
}

function _handleData(type, res) {
  switch (type) {
    case 1:
      return _handleNodes(res.data.orderTrackingNodeVOList || [])
    case 2:
      return _handleLog(res)
    case 3:
      return res.data
  }
}

function _handleLog(res) {
  const list = res.data.data || []
  return list.map((item) => {
    item.content = item.operationContent
    item.operationRecordType = item.operationType
    item.operatorNameCn = item.operatorName
    if (item?.content.includes('###split###')) {
      item.content = item.content.split('###split###')
    } else {
      item.content = [item.content]
    }

    return item
  })
}

function _getDeferredDays(item) {
  let { realDate, dueDate } = item
  realDate = new Date(realDate).setHours(0)
  realDate = new Date(realDate).setMinutes(0)
  realDate = new Date(realDate).setSeconds(0)
  dueDate = new Date(dueDate).setHours(0)
  dueDate = new Date(dueDate).setMinutes(0)
  dueDate = new Date(dueDate).setSeconds(0)
  let df = (_(realDate) - _(dueDate)) / 1000 / 60 / 60 / 24
  if (df <= 0) df = ''
  return df
}

/**
 * 询盘导出
 */

export function selectAllExcel(data = {}) {
  return request({
    url: '/order/orderEnquiry/selectAllExcel',
    method: 'post',
    data,
    responseType: 'blob',
  })
}

export function getDetailForEditShow(data) {
  return request({
    url: '/merchants/info/getDetailForOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 查看打印合同
 * @param {*} data
 * @returns
 */
export function getPurchaseContract(data) {
  return request({
    url: '/order/purchaseOrder/getPurchaseContract',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

/**
 * 采购订单入库 新增供应商账户
 */
export function saveSupplierBankAccount(data) {
  return request({
    url: '/merchants/supplierBankAccount/saveSupplierBankAccount',
    method: 'post',
    data,
  })
}

export function lookPurchaseStockUp(data) {
  return request({
    url: '/order/purchaseOrder/lookPurchaseStockUp',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

// 获取订单来源枚举列表
export function listOrderSource(data) {
  return request({
    url: '/order/orderEnquiry/listOrderSource ',
    method: 'get',
    data,
  })
}
