/* eslint-disable prettier/prettier */
import request from '@/core/services/request'

//根据分页查找列表
export function listPageMallOrder(data = {}) {
  return request({
    url: '/order/mallOrder/listPageMallOrder',
    method: 'post',
    data,
  })
}

//获取订单详情
export function getMallOrderVO(id) {
  return request({
    url: `/order/mallOrder/getMallOrderVO`,
    method: 'post',
    data: { id },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 新增商城新增订单
export function insertOrderToMall(data) {
  return request({
    url: '/order/mallOrder/insertOrderToMall',
    method: 'post',
    data,
  })
}



// 保存商城订单
export function saveMallOrder(data) {
  return request({
    url: '/order/mallOrder/updateMallOrder',
    method: 'post',
    data,
  })
}
