const translateConfig = {
  zh: {
    Documentary: {
      area: '地区',
      all: '全部',
      Hefei: '合肥',
      Suzhou: '苏州',
      Yiwu: '义乌',
      supplierName: '供应商名称',
      PE: '请输入',
      PC: '请选择',
      PON: '采购订单编号',
      OTN: '订单跟踪编号',
      ON: '订单编号',
      DCD: '单据创建日期',
      SD: '开始日期',
      ED: '结束日期',
      Role: '角色',
      state: '状态',
      warning: '预警中',
      postpone: '延期',
      TBA: '待安排',
      processing: '进行中',
      completed: '已完成',
      NNTD: '无需跟单',
      OOD: '已作废',
      search: '搜索',
      reset: '重置',
      AF: '高级筛选',
      add: '新增',
      export: '导出',
      save: '保存',
      CD: '自定义显示',
      remaining: '剩余',
      postponed: '已延期',
      day: '天',
      details: '详情',
      renew: '更新',
      void: '作废',
      remark: '备注',
      CW: '解除预警',
      recover: '恢复',
      SO: '操作成功',
      SS: '保存成功',
      Cancel: '取消',
      Sure: '确定',
      SN: '序号',
      PD: '采购开发',
      OS: '订单支持',
      CR: '客户代表',
      PN: '产品名称',
      Quantity: '产品数量',
      TPA: '产品总金额',
      TPOA: '采购单总金额',
      ITAS: '是否有样品',
      CN: '当前节点',
      FD: '工厂交期(周期)',
      expiry: '距离到期',
      WT: '预警时间',
      NSN: '节点序号',
      NN: '节点名称',
      ESD: '预计日期',
      ACD: '实际日期',
      DED: '延期天数',
      operate: '操作',
      Operator: '操作人',
      OPT: '操作时间',
      OPR: '操作记录',
      time: '时间',
      name: '姓名',
      Remarks: '备注内容',
      OLD: '操作日志详情',
      BI: '基本信息',
      AN: '关联编号',
      none: '无',
      have: '有',
      QI: '质检员(QC)',
      type: '类型',
      PI: '采购物品',
      choose: '选择',
      PRC: '产品编码',
      PQ: '采购数量',
      DN: '跟单节点',
      return: '返回',
      newAdd: '新增',
      IM: '备注说明',
      POD: '按需采购',
      SP: '备货采购',
      hint: '提示',
      PSO: '请选择一条',
      IR: '验货结果',
      IA: '验货附件',
      appendix: '附件',
      SHN: '物流单号',
      TU: '时间更新人',
      NNY: '暂无节点',
      NEN: '新增节点',
      IBTN: '在此节点之前增加',
      IATN: '在此节点之后增加',
      NNCBR: '节点名称不能重复',
      deleted: '已删除',
      UA: '上传附件',
      support: '支持',
      US: '上传成功',
      NT: '备注轨迹',
      TN: '物流单号',

      RCBE: '备注不能为空！',
      RANSD: '备注内容没有保存，是否关闭?',
      AONON: '关联单号：订单编号 或 备货单号',
      SAPNN: '备货采购：物流货运和客户签收无需填写',
      FDPOD: '工厂交期(周期)=采购订单.交货日期-采购订单.创建日期',
      WTRTW: '是否解除预警，解除预警后 状态恢复进行中。',
      AYSYD: '是否确定无需跟单？',
      ACSNN: '确认后 状态=无需跟单，并且不可逆',
      DYWTV: '是否要作废这笔单据?',
      DYWTR: '是否要恢复这笔单据?',
      TTCNA:
        '提示：当前节点【实际日期】为系统自动读取，请填写预计日期、附件、备注等信息。',
      TTCNI: '提示：当前节点【实际日期】为系统自动读取，请填写预计日期和备注。',
      TATCN:
        '提示：当前节点【实际日期】填写保存以后，上一个节点则不再支持编辑。',
      TIIRT: '提示：新增节点建议使用中英文双名称, 如',
      RN: '刷新节点',
      DA: '条数据',
      Belongingmodule: '归属模块',
    },
  },
  en: {
    Documentary: {
      area: 'Region',
      all: 'all',
      Hefei: 'Hefei',
      Suzhou: 'Suzhou',
      Yiwu: 'Yiwu',
      supplierName: 'Supplier',
      PE: 'please enter',
      PC: 'please choose',
      PON: 'Purchase order number',
      OTN: 'order tracking number',
      ON: 'order number',
      DCD: 'Ticket created',
      SD: 'start date',
      ED: 'end date',
      Role: 'Owner',
      state: 'Status',
      warning: 'Alert',
      postpone: 'Extended',
      TBA: 'To be arranged',
      processing: 'In production',
      completed: 'completed',
      NNTD: 'No need to document',
      OOD: 'Canceled',
      search: 'search',
      reset: 'reset',
      AF: 'Advanced filter',
      add: 'add',
      export: 'export',
      save: 'save',
      CD: 'custom display',
      remaining: 'remaining',
      postponed: 'postponed',
      day: 'day',
      details: 'details',
      renew: 'renew',
      void: 'void',
      remark: 'remark',
      CW: 'clear warning',
      recover: 'recover',
      SO: 'Successful operation',
      SS: 'Successfully saved',
      Cancel: 'Cancel',
      Sure: 'Sure',
      SN: '#',
      PD: 'Buyer',
      OS: 'Order assistant',
      CR: 'Sales',
      PN: 'Item',
      Quantity: 'Quantity',
      TPA: 'Order amount',
      TPOA: 'Total purchase order amount',
      ITAS: 'With sample or not',
      CN: 'Current stage',
      FD: 'Production time',
      expiry: 'Production remaining time',
      WT: 'Alert date',
      NSN: 'Stage code',
      NN: 'Stage name',
      ESD: 'estimated date',
      ACD: 'actual date',
      DED: 'Deferred days',
      operate: 'operate',
      Operator: 'Operator',
      OPT: 'Operation time',
      OPR: 'Operation record',
      time: 'time',
      name: 'name',
      Remarks: 'Remarks',
      OLD: 'Operation log details',
      BI: 'Basic Information',
      AN: 'Association number',
      none: 'none',
      have: 'have',
      QI: 'Quality Inspector (QC)',
      type: 'type',
      PI: 'Purchasing items',
      choose: 'choose',
      PRC: 'Item Number',
      PQ: 'Purchase quantity',
      DN: 'Stage',
      return: 'return',
      newAdd: 'Add Documentary',
      IM: 'instruction manual',
      POD: 'Purchase on demand',
      SP: 'Stock purchase',
      hint: 'hint',
      PSO: 'Please select one',
      IR: 'Inspection result',
      IA: 'Inspection accessories',
      appendix: 'appendix',
      SHN: 'shipment number',
      TU: 'Update',
      NNY: 'No node yet',
      NEN: 'new node',
      IBTN: 'Increment before this node',
      IATN: 'increment after this node',
      NNCBR: 'Stage name cannot be repeated',
      deleted: 'deleted',
      UA: 'Upload Attachment',
      support: 'support',
      US: 'Uploaded successfully',
      NT: 'Notes track',
      TN: 'tracking number',

      RCBE: 'Remarks cannot be empty!',
      RANSD: 'Remarks are not saved, do you want to close?',
      AONON: 'Associated order number: order number or stock order number',
      SAPNN:
        'Stocking and purchasing: no need to fill in logistics, freight and customer receipt',
      FDPOD:
        'Factory Delivery (Cycle) = Purchase Order.Delivery Date - Purchase Order.Creation Date',
      WTRTW:
        'Whether to release the warning, after the warning is released, the status will resume in progress.',
      AYSYD: "Are you sure you don't need to follow orders?",
      ACSNN: 'After confirmation status = no need to copy, and irreversible',
      DYWTV: 'Do you want to void this document?',
      DYWTR: 'Do you want to restore this document?',
      TTCNA:
        'Tip: The Current stage [actual date] is automatically read by the system, please fill in the expected date, attachments, remarks and other information.',
      TTCNI:
        'Tip: The Current stage [actual date] is automatically read by the system, please fill in the expected date and remarks.',
      TATCN:
        'Tip: After the Current stage [Actual Date] is filled in and saved, the previous node no longer supports editing.',
      TIIRT:
        'Tip: It is recommended to use both Chinese and English names for new nodes, such as',
      RN: 'refresh node',
      DA: 'datas',
      Belongingmodule: 'Belonging Module',
    },
  },
}

export default translateConfig
